<template>
  <div class="login-container">
    <div class="login-card">
      <h1>TaiTan内容系统</h1>
      <el-form :model="form" ref="form" label-width="100px">
        <el-form-item label="账号：">
          <el-input v-model="form.username" @keyup.enter="login"></el-input>
        </el-form-item>
        <el-form-item label="密码：">
          <el-input type="password" v-model="form.password" @keyup.enter="login"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="login">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="wave wave1"></div>
    <div class="wave wave2"></div>
    <div class="wave wave3"></div>
  </div>
</template>

<script>
import axios from '@/api/axiosInstance';

export default {
  data() {
    return {
      form: {
        username: '',
        password: ''
      }
    };
  },
  methods: {
    login() {
      axios.post('/api/login', this.form).then(response => {
        if (response.data.data.accessToken) {
          localStorage.setItem('token', response.data.data.accessToken);
          localStorage.setItem('username', response.data.data.userInfo.username);
          localStorage.setItem('tenantId', response.data.data.userInfo.tenantId);
          localStorage.setItem('isSuperAdmin', response.data.data.isSuperAdmin);
          localStorage.setItem('routePaths', response.data.data.routePaths);
          this.$message.success('登录成功');
          this.$router.push('/');
        } else {
          this.$message.error('登录失败，请重试');
        }
      }).catch(error => {
        console.error(error);
        this.$message.error('登录失败，请检查账号或密码');
      });
    }
  }
};
</script>

<style scoped>
.login-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(to bottom, #ff9a9e 0%, #fad0c4 20%, #c6e2ff 60%, #ffffff 100%);
}

.login-card {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  width: 400px;
  text-align: center;
  z-index: 10;
}

h1 {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 24px;
}

.wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 200%;
  height: 25%;
  background: rgba(135, 206, 250, 0.5);
  border-radius: 100% 50% 0 0;
  animation: wave-up-down 4s ease-in-out infinite;
}

.wave1 {
  height: 30%;
  animation-duration: 6s;
  background: rgba(135, 206, 250, 0.6);
}

.wave2 {
  height: 35%;
  animation-duration: 8s;
  background: rgba(135, 206, 250, 0.4);
}

.wave3 {
  height: 40%;
  animation-duration: 10s;
  background: rgba(135, 206, 250, 0.3);
}

@keyframes wave-up-down {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
</style>
