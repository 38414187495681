import {createStore} from 'vuex';

const store = createStore({
    state: {
        tenantId: null,
        isSuperAdmin: false,
        userInfo: null,
        accessToken: null,
        routePaths: [],
    },
    mutations: {
        setTenantId(state, tenantId) {
            state.tenantId = tenantId;
        },
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo;
        },
        setAccessToken(state, accessToken) {
            state.accessToken = accessToken;
        },
        setIsSuperAdmin(state, isSuperAdmin) {
            state.isSuperAdmin = isSuperAdmin;
        },
        setRoutePaths(state, routePaths) {
            state.routePaths = routePaths;
        },
        clearLoginData(state) {
            state.tenantId = null;
            state.userInfo = null;
            state.accessToken = null;
            state.isSuperAdmin = false;
            state.routePaths = [];
        }
    },
    actions: {
        login({commit}, loginData) {
            commit('setRoutePaths', loginData.routePaths)
            commit('setTenantId', loginData.userInfo.tenantId);
            commit('setUserInfo', loginData.userInfo);
            commit('setAccessToken', loginData.accessToken);
            commit('setIsSuperAdmin', loginData.isSuperAdmin);
        },
        logout({commit}) {
            commit('clearLoginData');
        },
    },
});

export default store;
