<template>
  <el-container style="height: 100vh;">
    <el-aside width="200px">
      <el-menu
          active-text-color="#ffd04b"
          background-color="#545c64"
          class="menu-demo"
          :default-active="activeIndex"
          text-color="#fff"
          @open="handleOpen"
          @close="handleClose"
          :collapse="false"
          :router="true"
      >
        <el-sub-menu v-if="isSuperAdmin || dyAccountManagerShow || dyAccountWorkShow" index="douyin-manage">
          <template #title>
            <douyin-icon class="el-icon"></douyin-icon>
            <span>抖音管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item v-if="isSuperAdmin || dyAccountManagerShow" index="/douyin/account">
              <el-icon>
                <User/>
              </el-icon>
              <span>账号管理</span>
            </el-menu-item>
            <el-menu-item v-if="isSuperAdmin || dyAccountWorkShow" index="/douyin/work">
              <el-icon>
                <Promotion/>
              </el-icon>
              <span>作品管理</span>
            </el-menu-item>
            <el-menu-item v-if="isSuperAdmin || dyAccountWorkDataShow" index="/douyin/work-data">
              <el-icon>
                <Histogram/>
              </el-icon>
              <span>作品数据</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>

        <el-sub-menu v-if="isSuperAdmin || lumaAccountShow || lumaWorkShow" index="luma-manage">
          <template #title>
            <luma-icon class="el-icon"></luma-icon>
            <span>自动生成</span>
          </template>
          <el-menu-item-group>
            <el-menu-item v-if="isSuperAdmin || lumaAccountShow" index="/video-create/account">
              <el-icon>
                <User/>
              </el-icon>
              <span>账号管理</span>
            </el-menu-item>
            <el-menu-item v-if="isSuperAdmin || lumaWorkShow" index="/video-create/work">
              <el-icon>
                <Promotion/>
              </el-icon>
              <span>作品管理</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>

        <el-sub-menu v-if="isSuperAdmin || contentMaterialShow || contentProductShow" index="content-manage">
          <template #title>
            <el-icon>
              <Promotion/>
            </el-icon>
            <span>内容管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item v-if="isSuperAdmin || contentMaterialShow" index="/content/material">
              <el-icon>
                <HelpFilled/>
              </el-icon>
              <span>素材管理</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>

        <el-sub-menu v-if="isSuperAdmin || aiTryOnMenuShow || aiTryOnDemoShow" index="ai-try-one">
          <template #title>
            <cloth-icon class="el-icon"></cloth-icon>
            <span>AI换装</span>
          </template>
          <el-menu-item-group>
            <el-menu-item v-if="isSuperAdmin || aiTryOnDemoShow" index="/ai-try-on/demo">
              <el-icon>
                <HelpFilled/>
              </el-icon>
              <span>换装Demo</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>

      </el-menu>
    </el-aside>
    <el-container>
      <el-main>
        <el-page-header :icon="null">
          <template #title>
            <span class="text-large font-600 mr-3"></span>
          </template>
          <template #content>
            <div class="flex items-center">
              <span class="text-large font-600 mr-3"> {{ username }} </span>
            </div>
          </template>
          <template #extra>
            <div class="flex items-center">
              <el-button type="text" @click="handleLoginOut">退出登录</el-button>
            </div>
          </template>
        </el-page-header>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import {
  Document, Menu as IconMenu, Location, Setting, User, Promotion, HelpFilled, Message, Histogram
} from '@element-plus/icons-vue';

import DouyinIcon from '@/components/icons/DouyinIcon.vue';
import LumaIcon from "@/components/icons/LumaIcon.vue";
import FeatureIcon from '@/components/icons/FeatureIcon.vue';
import ClothIcon from '@/components/icons/ClothIcon.vue';

import {mapState} from "vuex";

export default {
  components: {
    DouyinIcon, LumaIcon, ClothIcon,
    Message, Document, IconMenu, Location, Setting,
    User, Promotion, HelpFilled, Histogram
  },
  computed: {
    isSuperAdmin() {
      return localStorage.getItem('isSuperAdmin') === 'true';
    },
    tenantId() {
      return localStorage.getItem('tenantId') || '';
    },
    username() {
      return localStorage.getItem('username') || ''
    },
    routePaths() {
      return localStorage.getItem('routePaths') || '';
    },
  },
  mounted() {
    console.log('isSuperAdmin:', this.isSuperAdmin);
    console.log('Current Tenant ID:', this.tenantId);
    console.log('Username:', this.username);
    console.log('home page routePaths:', this.routePaths);
    this.updateMenuVisibility(this.routePaths);
  },
  data() {
    return {
      dyAccountManagerShow: false,
      dyAccountWorkShow: false,
      dyAccountWorkDataShow: false,
      lumaAccountShow: false,
      lumaWorkShow: false,
      contentMaterialShow: false,
      contentProductShow: false,

      aiTryOnMenuShow: false, // AI换装菜单
      aiTryOnDemoShow: false, // AI换装Demo

      activeIndex: this.$route.path,
      pageTitle: this.$route.meta.title || 'Home',

    };
  },
  watch: {
    $route(to) {
      this.activeIndex = to.path;
    },
  },
  methods: {
    updateMenuVisibility(routePaths) {
      if (routePaths) {
        const paths = routePaths.split(',');
        this.dyAccountManagerShow = paths.includes('/douyin/account');
        this.dyAccountWorkShow = paths.includes('/douyin/work');
        this.dyAccountWorkDataShow = paths.includes('/douyin/work-data');
        this.lumaAccountShow = paths.includes('/video-create/account');
        this.lumaWorkShow = paths.includes('/video-create/work');
        this.contentMaterialShow = paths.includes('/content/material');
        this.contentProductShow = paths.includes('/content/work');
      } else {
        this.dyAccountManagerShow = false;
        this.dyAccountWorkShow = false;
        this.dyAccountWorkDataShow = false;
        this.lumaAccountShow = false;
        this.lumaWorkShow = false;
        this.contentMaterialShow = false;
        this.contentProductShow = false;
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleLoginOut() {
      this.$store.dispatch('logout');
      localStorage.clear();
      this.$router.push('/login');
    },
  },
};
</script>

<style scoped>
.menu-demo {
  height: 100%;
}
</style>