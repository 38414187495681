<template>
  <div>
    <h1 class="main-header-h1">自动生成账号信息展示</h1>
    <!--搜索区域-->
    <el-form ref="queryFormRef" :model="queryParams" :inline="true" label-width="68px">
      <el-form-item label="用户名" prop="platformUserNickname">
        <el-input v-model="queryParams.platformUserNickname" placeholder="用户名" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="fetchCookieInfo">
          <el-icon>
            <Search/>
          </el-icon>
          <span>搜索</span>
        </el-button>
        <el-button type="success" @click="addDouyinUser">
          <el-icon>
            <CirclePlusFilled/>
          </el-icon>
          <span>添加账号</span>
        </el-button>
        <el-button :loading="checkAccountLoading" type="primary"
                   :disabled="selectedIds === null || selectedIds.length === 0"
                   @click="checkAccountCookie">
          <el-icon>
            <Check/>
          </el-icon>
          <span>一键校验</span>
        </el-button>
      </el-form-item>
    </el-form>
    <!--表格数据-->
    <el-table :data="cookieInfoList" v-loading="loading" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"/>
      <el-table-column prop="id" label="ID"></el-table-column>
      <!--      <el-table-column prop="platform_type" label="平台类型"></el-table-column>-->
      <el-table-column prop="platform_user_nickname" label="平台用户名"></el-table-column>
      <!--      <el-table-column prop="platform_user_id" label="平台用户ID"/>-->
      <!--      <el-table-column prop="platform_user_avatar" label="平台用户头像"/>-->
      <el-table-column prop="content" width="300" label="cookie">
        <template #default="scope">
          <el-tooltip class="item" effect="dark" :content="scope.row.content" placement="top">
            <span>{{
                scope.row.content.length > 30 ? scope.row.content.substring(0, 30) + '...' : scope.row.content
              }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="meta_data" width="350" label="账号信息">
        <template #default="scope">
          <div>
            <el-row v-for="(value, key) in parseMetaData(scope.row.meta_data)" :key="key">
              <el-col :span="8">
                <el-tag type="info" class="meta-key">{{ key }}:</el-tag>
              </el-col>
              <el-col :span="16">
                <el-tag class="meta-value">{{ value }}</el-tag>
              </el-col>
            </el-row>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="login_time" label="最近登录时间"/>
      <el-table-column prop="expire_time" label="预计到期时间"/>
      <el-table-column prop="status" label="账号状态">
        <template #default="scope">
          <el-tag
              :type="scope.row.status === 1 ? 'success' : 'warning'"
              disable-transitions
          >{{ scope.row.status === 1 ? '有效' : '无效' }}
          </el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" width="160"></el-table-column>
      <el-table-column fixed="right" label="操作" width="160">
        <template #default="scope">
          <div style="display: flex; flex-direction: column; align-items: flex-start;">
            <el-button link disabled></el-button>
            <el-button style="margin-bottom: 5px;" link type="info" @click.prevent="clickSeeLumaWork(scope.row)">
              查看作品
            </el-button>
            <el-button style="margin-bottom: 5px;" link type="success" @click.prevent="clickCheckCookie(scope.row)">
              校验Cookie
            </el-button>
            <el-button style="margin-bottom: 5px;" link type="primary" @click.prevent="editAccount(scope.row)">编辑
            </el-button>
            <el-button style="margin-bottom: 5px;" link type="danger" @click.prevent="deleteLumaAccount(scope.row)">
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--分页数据-->
    <pagination v-show="total > 0" :total="total" v-model:page="queryParams.pageNum"
                v-model:limit="queryParams.pageSize" @pagination="fetchCookieInfo"/>
    <!--添加账号-->
    <el-dialog title="添加账号"
               v-model="showAddUserDialog"
               width="40%"
               :close-on-click-modal="false">
      <el-form
          :model="userInfoForm"
          :rules="rules"
          ref="userInfoForm"
          label-position="left"
          label-width="auto"
          style="max-width: 600px"
      >
        <el-form-item label="email" prop="email">
          <el-input v-model="userInfoForm.email"
                    placeholder="可选，请输入账号">
          </el-input>
        </el-form-item>
        <el-form-item label="password" prop="password">
          <el-input
              v-model="userInfoForm.password"
              type="password"
              placeholder="可选，请输入密码"
              show-password
          />
        </el-form-item>
        <el-form-item label="cookie" prop="cookie">
          <el-input :rows="4"
                    type="textarea"
                    placeholder="必填，请输入cookie"
                    v-model="userInfoForm.cookie"/>
        </el-form-item>
        <el-form-item label="remark" prop="remark">
          <el-input
              placeholder="可选，备注"
              v-model="userInfoForm.remark"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('userInfoForm')" :loading="submitLoading">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import axiosInstance from '@/api/axiosInstance';
import {CirclePlusFilled, Search, Download, Check} from '@element-plus/icons-vue';
import Pagination from "@/components/pagination/index.vue";

export default {
  name: "luma-account",
  components: {
    Pagination,
    CirclePlusFilled, Search, Download, Check
  },
  data() {
    const validateCookie = (rule, value, callback) => {
      if (!value) {
        callback(new Error('Cookie不能为空'));
      }
      callback();
    };
    return {
      checkAccountLoading: false,
      selectedIds: [],
      queryParams: {
        pageNum: 1,
        pageSize: 20,
        platformUserNickname: "",
      },
      reportTableData: [],
      loading: false,
      total: 0,

      loginRecord: {},
      cookieInfoList: [],
      showAddUserDialog: false,
      loginId: '',
      activeStep: 0,
      loginStatus: '',
      verifyCode: '',
      qrCodeRemainingTime: 0,
      cookieTimer: null,
      submitLoading: false,
      userInfoForm: {
        email: '',
        password: '',
        cookie: '',
        remark: '',
      },
      rules: {
        email: [{type: 'email', message: '请输入有效的邮箱地址', trigger: 'blur'}],
        password: [],
        cookie: [{required: true, validator: validateCookie, trigger: 'blur'}]
      }
    };
  },
  created() {
    this.fetchCookieInfo();
    // this.startCookieTimer();
  },
  methods: {
    handleSelectionChange(rows) {
      console.log("handleSelectionChange", rows)
      // rows 对象数组 取id字段 转对应数组
      this.selectedIds = rows.map(item => item.id) || [];
    },
    parseMetaData(metaData) {
      try {
        return JSON.parse(metaData);
      } catch (e) {
        return {};
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveCookieInfo();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    saveCookieInfo() {
      this.submitLoading = true;
      axiosInstance.post('/api/luma/save_account', this.userInfoForm).then(response => {
        if (response.data && response.data.code === 0) {
          this.$message.success("用户信息更新成功");
        } else {
          this.$message.error("用户信息更新失败, 请稍后再试");
        }
        this.submitLoading = false;
        this.showAddUserDialog = false;
        this.fetchCookieInfo();
      }).catch(error => {
        console.error(error)
        this.submitLoading = false;
        this.showAddUserDialog = false;
      });
    },
    fetchCookieInfo() {
      this.loading = true
      axiosInstance.post('/api/luma/account/list', this.queryParams).then(response => {
        if (response.data && response.data.code === 0) {
          this.cookieInfoList = response.data.data.results;
          this.total = response.data.data.total;
        } else {
          this.$message.error(response.data.msg);
        }
        this.loading = false
      }).catch(error => {
        this.loading = false
        console.error(error)
      });
    },
    addDouyinUser() {
      this.showAddUserDialog = true;
    },
    clickCheckCookie(row) {
      this.selectedIds = [row.id]
      this.checkAccountCookie()
    },
    editAccount(row) {
      this.userInfoForm.email = row.email
      this.userInfoForm.cookie = row.content
      this.userInfoForm.remark = row.remark
      this.showAddUserDialog = true;
    },
    checkAccountCookie() {
      let that = this;
      that.checkAccountLoading = true;
      // 用户点击了确定按钮，发送删除请求
      axiosInstance.post('/api/luma/cookie_check', {
        ids: this.selectedIds
      }).then(response => {
        that.checkAccountLoading = false;
        if (response && response.data && response.data.code === 0) {
          that.$message({
            type: 'success',
            message: '用户Cookie校验结束!'
          });
        } else {
          that.$message({
            type: 'error',
            message: '用户Cookie校验失败，请稍后重试'
          });
        }
        // 刷新用户信息列表
        that.fetchCookieInfo();
      }).catch(error => {
        that.checkAccountLoading = false;
        that.$message({
          type: 'error',
          message: '用户Cookie校验失败: ' + error.message
        });
      });
    },
    deleteLumaAccount(row) {
      let that = this;
      this.$confirm('此操作将删除该用户信息, 是否继续?', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 用户点击了确定按钮，发送删除请求
        axiosInstance.get(`/api/luma/user/delete/${row.id}`)
            .then(response => {
              that.$message({
                type: 'success',
                message: '用户信息删除成功!'
              });
              // 删除成功后，刷新用户信息列表
              that.fetchCookieInfo();
            })
            .catch(error => {
              that.$message({
                type: 'error',
                message: '删除用户信息失败: ' + error.message
              });
            });
      }).catch(() => {
        // 用户点击了取消按钮
        that.$message({
          type: 'info',
          message: '已取消删除操作'
        });
      });
    },
    clickSeeLumaWork(row) {
      const platformUserId = row.platform_user_id;
      this.$router.push({path: `/luma/work`, query: {platformUserId: platformUserId}});
    },
    startCookieTimer() {
      this.cookieTimer = setInterval(() => {
        // this.fetchLoginRecord();
        this.fetchCookieInfo();
      }, 30000);
      console.log("自动获取账号已开启...")
    },
    stopAllTimer() {
      console.log('stopAllTimer')
      if (this.cookieTimer) {
        clearInterval(this.cookieTimer);
      }
    },
  },
  beforeUnmount() {
    this.stopAllTimer()
  }
};
</script>

<style scoped>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 20px;
}

.main-header-h1 {
  text-align: center;
  color: #333;
}

#button-container {
  text-align: center;
  margin-bottom: 20px;
}

.add-user-button {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-user-button:hover {
  background-color: #0056b3;
}

#cookie-info-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.cookie-info {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.cookie-info h2 {
  margin: 0 0 10px;
  font-size: 1.5em;
  font-weight: 600;
  color: #333;
}

.cookie-info span {
  margin-bottom: 5px;
  font-size: 1em;
  color: #555;
}

.cookie-status-valid {
  color: green;
}

.cookie-status-invalid {
  color: red;
}

.step-content {
  text-align: center;
  margin-top: 20px;
}

.highlight {
  color: red;
  font-weight: bold;
}
</style>