// src/router/index.js
import {createRouter, createWebHistory} from 'vue-router';
import Login from '@/views/Login.vue';
import Home from '@/views/Home.vue';
import DouyinLoginInfo from '@/components/douyin/DouyinAccountInfo.vue';
import LumaAccount from "@/components/luma/LumaAccount.vue";
import LumaWorkManager from "@/components/luma/LumaWorkManager.vue";
import DouyinWorkManager from "@/components/douyin/DouyinWorkManager.vue";
import DouyinWorkData from "@/components/douyin/DouyinWorkData.vue";
import MaterialRecordInfo from "@/components/content_manage/MaterialRecordInfo.vue";
import AITryOneDemo from "@/components/ai_try_on/AITryOneDemo.vue";

const routes = [
    {
        path: '/',
        redirect: '/douyin/account'
    },
    {
        path: '/login',
        component: Login,
    },
    {
        path: '/',
        component: Home,
        children: [
            {
                path: '/douyin/account',
                component: DouyinLoginInfo,
                meta: {title: '抖音账号信息展示'}
            },
            {
                path: '/douyin/work',
                component: DouyinWorkManager,
                meta: {title: '抖音作品管理'}
            },
            {
                path: '/douyin/work-data',
                component: DouyinWorkData,
                meta: {title: '抖音作品数据'}
            },
            {
                path: '/video-create/account',
                component: LumaAccount,
                meta: {title: '视频自动生成账号信息展示'}
            },
            {
                path: '/video-create/work',
                component: LumaWorkManager,
                meta: {title: '视频自动生成作品管理'}
            },
            {
                path: '/content/material',
                component: MaterialRecordInfo,
                meta: {title: '素材管理'}
            },
            {
                path: '/content/work',
                component: MaterialRecordInfo,
                meta: {title: '成品管理'}
            },
            {
                path: '/ai-try-on/demo',
                component: AITryOneDemo,
                meta: {title: 'AI一键换装Demo'}
            }
        ]
    }
];


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;