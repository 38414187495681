<template>
  <div>
    <h1 class="main-header-h1">抖音作品数据</h1>
    <!--搜索区域-->
    <el-form ref="queryFormRef" :model="queryParams" :inline="true" label-width="100px">
      <el-form-item v-if="isSuperAdmin" label="租户：" prop="tenantId">
        <el-select v-model="queryParams.tenantId" style="width: 200px" placeholder="请选择租户" clearable>
          <el-option
              v-for="tenant in tenantInfoList"
              :key="tenant.tenantId"
              :label="`${tenant.tenantName} (${tenant.status == 1 ? '有效' : '无效'})`"
              :value="tenant.tenantId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="抖音用户：" prop="platformUserId">
        <el-select v-model="queryParams.platformUserId" style="width: 300px" placeholder="请选择抖音用户" clearable>
          <el-option
              v-for="account in accountInfoList"
              :key="account.id"
              :label="`${account.platform_user_nickname} (${account.status == 1 ? '有效' : '无效'})`"
              :value="account.platform_user_id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="作品ID：" prop="remark">
        <el-input v-model="queryParams.platformWorkId" placeholder="作品ID" clearable></el-input>
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input v-model="queryParams.remark" placeholder="备注" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="fetchDouyinWorkData" :loading="loading">
          <el-icon>
            <Search/>
          </el-icon>
          <span>搜索</span>
        </el-button>
      </el-form-item>
    </el-form>
    <!--表格数据-->
    <el-table :data="workDataList" v-loading="loading" @sort-change="handleSortChange">
      <el-table-column prop="platform_user_nickname" width="100" label="抖音昵称"></el-table-column>
      <el-table-column prop="platform_user_id" width="100" label="抖音ID"/>
      <el-table-column prop="platform_work_id" label="作品ID"></el-table-column>
      <el-table-column prop="platform_work_url" label="抖音平台作品链接" width="200">
        <template #default="scope">
          <div>{{ 'https://www.douyin.com/video/' + scope.row.platform_work_id }}</div>
          <el-button type="text" @click="gotoDouyinWorkUrl(scope.row.platform_work_id)">点击前往</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="platform_work_title" label="作品标题" width="250"></el-table-column>
      <!-- 添加可排序的表格列 -->
      <el-table-column prop="post_time" label="发布时间" sortable="custom"></el-table-column>
      <el-table-column prop="play_count" label="播放数" sortable="custom"></el-table-column>
      <el-table-column prop="comment_count" label="评论数" sortable="custom"></el-table-column>
      <el-table-column prop="like_count" label="点赞数" sortable="custom"></el-table-column>
      <el-table-column prop="favorite_count" label="收藏数" sortable="custom"></el-table-column>
      <el-table-column prop="download_count" label="下载数" sortable="custom"></el-table-column>
      <el-table-column prop="forward_count" label="转发数" sortable="custom"></el-table-column>
      <el-table-column prop="share_count" label="分享数" sortable="custom"></el-table-column>
      <el-table-column prop="update_time" label="更新时间"/>
      <!--      <el-table-column fixed="right" label="操作" width="120">-->
      <!--        <template #default="scope">-->
      <!--          <div style="display: flex; flex-direction: column; align-items: flex-start;">-->
      <!--            <el-button link disabled></el-button>-->
      <!--            <el-button v-if="isSuperAdmin" style="margin-bottom: 5px;" type="primary"-->
      <!--                       @click="clickRePublish(scope.row)">重新发布-->
      <!--            </el-button>-->
      <!--            <el-button link disabled></el-button>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </el-table>
    <!--分页数据-->
    <pagination :total="total" v-model:page="queryParams.pageNum"
                v-model:limit="queryParams.pageSize" @pagination="fetchDouyinWorkData"/>
  </div>
</template>

<script>
import axiosInstance from '@/api/axiosInstance';
import {CirclePlusFilled, Search, Download, Delete} from '@element-plus/icons-vue';
import Pagination from "@/components/pagination/index.vue";
import axios from 'axios';
import VideoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "douyin-work-data",
  components: {
    VideoPlayer,
    Pagination,
    CirclePlusFilled, Search, Download, Delete
  },
  computed: {
    isSuperAdmin() {
      return localStorage.getItem('isSuperAdmin') === 'true';
    },
    tenantId() {
      return localStorage.getItem('tenantId') || '';
    },
    username() {
      return localStorage.getItem('username') || ''
    },
    routePaths() {
      return localStorage.getItem('routePaths') || '';
    },
  },
  data() {
    return {
      tenantInfoList: [],
      workDataList: [],
      queryParams: {
        tenantId: "",
        pageNum: 1,
        pageSize: 20,
        platformUserId: "",
        platformUserNickname: "",
        platformWorkId: "",
        orderColumn: "id",
        orderType: "desc",
        remark: "",
      },
      accountInfoList: [],
      loading: false,
      total: 0,
    };
  },
  created() {
    if (this.$route.query.platformUserId) {
      this.queryParams.platformUserId = this.$route.query.platformUserId
    }
    if (this.$route.query.platformWorkId) {
      this.queryParams.platformWorkId = this.$route.query.platformWorkId;
    }
  },
  mounted() {
    if (this.isSuperAdmin) {
      axiosInstance.get('/api/tenant')
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.tenantInfoList = response.data.data;
            } else {
              this.$message.error(response.data.msg);
            }
          }).catch(error => {
        console.error(error);
      });
    }
    this.fetchDouyinAccountInfo();
    this.fetchDouyinWorkData();
  },
  methods: {
    gotoDouyinWorkUrl(platformWorkId) {
      const url = `https://www.douyin.com/video/${platformWorkId}`;
      window.open(url, '_blank');
    },
    fetchDouyinWorkData() {
      this.loading = true
      if (!this.isSuperAdmin) {
        this.queryParams.tenantId = this.tenantId
      }
      axiosInstance.post('/api/douyin/work_data/list', this.queryParams)
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.workDataList = response.data.data.results;
              this.total = response.data.data.total;
            } else {
              this.$message.error(response.data.msg);
            }
            this.loading = false
          }).catch(error => {
        this.loading = false
        console.error(error)
      });
    },
    handleSortChange({prop, order}) {
      // 设置排序字段和顺序
      this.queryParams.orderColumn = prop;
      this.queryParams.orderType = order === 'ascending' ? 'asc' : 'desc';
      // 重新获取数据
      this.fetchDouyinWorkData();
    },
    fetchDouyinAccountInfo() {
      let queryParamsNew = {...this.queryParams};
      queryParamsNew.pageSize = 1000
      if (!this.isSuperAdmin) {
        queryParamsNew.tenantId = this.tenantId;
      }
      axiosInstance.post('/api/douyin/account/list', queryParamsNew)
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.accountInfoList = response.data.data.results;
              this.total = response.data.data.total;
            } else {
              this.$message.error(response.data.msg);
            }
          }).catch(error => {
        console.error(error)
      });
    },
  },
  beforeUnmount() {
  }
};
</script>

<style scoped>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 20px;
}

.main-header-h1 {
  text-align: center;
  color: #333;
}

#button-container {
  text-align: center;
  margin-bottom: 20px;
}

.add-user-button {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-user-button:hover {
  background-color: #0056b3;
}

#cookie-info-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.cookie-info {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.cookie-info h2 {
  margin: 0 0 10px;
  font-size: 1.5em;
  font-weight: 600;
  color: #333;
}

.cookie-info span {
  margin-bottom: 5px;
  font-size: 1em;
  color: #555;
}

.cookie-status-valid {
  color: green;
}

.cookie-status-invalid {
  color: red;
}

.step-content {
  text-align: center;
  margin-top: 20px;
}

.highlight {
  color: red;
  font-weight: bold;
}

.photo-wall {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-height: 200px;
  overflow: auto;
}

.photo-item {
  width: calc(33.33% - 10px);
  cursor: pointer;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.photo-item img {
  width: 100%;
  height: auto;
}

.photo-item.selected {
  border: 2px solid #409eff;
}

.actions {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background: rgba(0, 0, 0, 0.5);
  color: #d9ecff;
  padding: 5px 0;
}

.actions span {
  cursor: pointer;
}
</style>