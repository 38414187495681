<template>
  <div>
    <h1 class="main-header-h1">素材管理</h1>
    <!--搜索区域-->
    <el-form ref="queryFormRef" :model="queryParams" :inline="true" label-width="100px">
      <el-form-item v-if="isSuperAdmin" label="租户：" prop="tenantId">
        <el-select v-model="queryParams.tenantId" style="width: 200px" placeholder="请选择租户" clearable>
          <el-option
              v-for="tenant in tenantInfoList"
              :key="tenant.tenantId"
              :label="`${tenant.tenantName} (${tenant.status == 1 ? '有效' : '无效'})`"
              :value="tenant.tenantId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="素材名：" prop="name">
        <el-input v-model="queryParams.name" placeholder="请输入素材名" clearable></el-input>
      </el-form-item>
      <el-form-item label="素材标签：" prop="tag">
        <el-select v-model="queryParams.tag" style="width: 200px" placeholder="请选择素材标签" clearable>
          <el-option
              v-for="tag in allTags"
              :key="tag.code"
              :label="tag.name"
              :value="tag.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input v-model="queryParams.remark" placeholder="备注" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="fetchMaterialInfo" :loading="loading">
          <el-icon>
            <Search/>
          </el-icon>
          <span>搜索</span>
        </el-button>
        <el-button type="success" @click="clickAddMaterialInfo">
          <el-icon>
            <CirclePlusFilled/>
          </el-icon>
          <span>上传素材</span>
        </el-button>
        <el-button type="warning"
                   :disabled="selectedIds === null || selectedIds.length === 0"
                   @click="clickBatchEditTags">
          <el-icon>
            <Edit/>
          </el-icon>
          <span>批量修改标签</span>
        </el-button>
      </el-form-item>
    </el-form>
    <!--表格数据-->
    <el-table :data="materialInfoList" v-loading="loading" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"/>
      <!--      <el-table-column prop="id" width="50" label="ID"></el-table-column>-->
      <!--      <el-table-column prop="code" label="素材编码" width="200"></el-table-column>-->
      <el-table-column prop="name" label="素材名" width="100"/>
      <el-table-column prop="type" label="素材类型" width="100">
        <template #default="scope">
          <el-tag type="primary">{{ getMaterialTypeText(scope.row.type) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="level_type" label="素材阶段" width="100">
        <template #default="scope">
          <el-tag type="success">{{ getLevelTypeText(scope.row.level_type) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="tags" label="标签" width="200px">
        <template #default="scope">
          <el-input :rows="2"
                    type="textarea" v-model="scope.row.tags" @change="clickEditTags(scope.row)"
                    placeholder="输入素材标签"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="use_count" label="素材使用次数" width="120"></el-table-column>
      <el-table-column prop="oss_url" label="预览" width="300">
        <template #default="scope">
          <div v-if="isVideo(scope.row.oss_url)">
            <div style="width: 100%">
              <video width="100%" controls>
                <source :src="scope.row.oss_url" type="video/mp4">
                您的浏览器不支持视频播放。
              </video>
            </div>
          </div>
          <div v-else-if="isImage(scope.row.oss_url)">
            <img :src="scope.row.oss_url" alt="图片预览" style="width: 100%; max-height: 100%;">
          </div>
          <div v-else>
            <el-input :rows="3"
                      type="textarea" v-model="scope.row.content" @change="editMaterialContent(scope.row)"
                      placeholder="修改素材内容"></el-input>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="oss_url" width="100" label="视频地址">
        <template #default="scope">
          <el-button v-if="scope.row.oss_url" type="primary" @click="handleCopy(scope.row.oss_url)">复制</el-button>
          <el-button v-else link type="warning" disabled>暂无</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间"/>
      <el-table-column prop="remark" label="备注" width="200">
        <template #default="scope">
          <el-input :rows="2"
                    type="textarea" v-model="scope.row.remark" @change="editRemark(scope.row)"
                    placeholder="输入备注"></el-input>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template #default="scope">
          <div style="display: flex; flex-direction: column; align-items: flex-start;">
            <el-button link disabled></el-button>
            <el-button style="margin-bottom: 5px;" link type="danger"
                       @click="clickDeleteMaterialInfo(scope.row)">删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--分页数据-->
    <pagination v-show="total > 0" :total="total" v-model:page="queryParams.pageNum"
                v-model:limit="queryParams.pageSize" @pagination="fetchMaterialInfo"/>

    <!--上传素材-->
    <el-dialog v-model="addMaterialDialogVisible"
               title="上传素材"
               width="40%"
               destroy-on-close
               @opened="resetAddMaterialForm"
               :close-on-click-modal="false">
      <el-form
          :model="addMaterialForm"
          :rules="addMaterialRules"
          ref="addMaterialInfoForm"
          label-position="left"
          label-width="auto">
        <el-form-item label="素材名称：" prop="name">
          <el-input
              placeholder="可选，请输入素材名称，不填将自动生成"
              v-model="addMaterialForm.name"/>
        </el-form-item>
        <el-form-item label="素材阶段：" prop="levelType">
          <el-radio-group v-model="addMaterialForm.levelType">
            <el-radio value="raw" disabled>原始</el-radio>
            <el-radio value="pre" disabled>预处理</el-radio>
            <el-radio value="mid" disabled>中间物</el-radio>
            <el-radio value="end">成品</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="素材标签：" prop="tags">
          <div class="tag-container">
            <div class="tag-flex tag-gap-2">
              <el-check-tag
                  v-for="tag in allTags"
                  :key="tag.id"
                  type="primary"
                  :checked="tag.checked"
                  @change="clickTag(tag)">
                {{ tag.name }}
              </el-check-tag>
            </div>
            <div class="custom-tag-input">
              <el-input
                  v-model="newTag"
                  style="max-width: 200px"
                  placeholder="新增自定义标签"
                  class="input-with-select">
                <template #append>
                  <el-button @click="addCustomTag" :loading="addTagLoading">
                    <el-icon>
                      <Position/>
                    </el-icon>
                  </el-button>
                </template>
              </el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="素材类型：" prop="type">
          <el-radio-group v-model="addMaterialForm.type">
            <el-radio value="video">视频</el-radio>
            <el-radio value="image">图片</el-radio>
            <el-radio value="audio">音频</el-radio>
            <el-radio value="text">文本</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="addMaterialForm.type ==='video'" label="上传视频：" prop="ossUrl">
          <el-upload
              style="min-width: 600px"
              ref="upload"
              :multiple=true
              :on-remove="handleRemoveFile"
              :file-list="fileList"
              :limit="50"
              :on-exceed="handleExceed"
              :before-upload="beforeUpload"
              list-type="text"
              accept="video/*"
              :on-preview="handlePictureCardPreview"
              :http-request="uploadToOss">
            <el-button type="primary" link :loading="uploadFileLoading">
              <el-icon>
                <CirclePlusFilled/>
              </el-icon>
              点击上传
            </el-button>
            <template #tip>
              <div class="el-upload__tip">
                请上传小于50MB的视频，最多支持同时上传50个.
              </div>
            </template>
          </el-upload>
        </el-form-item>
        <el-form-item v-else-if="addMaterialForm.type ==='text'" label="输入文本：" prop="content" required>
          <el-input
              :rows="4"
              type="textarea"
              placeholder="请输入文本内容，多个文本请换行"
              v-model="addMaterialForm.content"/>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
              :rows="2"
              type="textarea"
              placeholder="可选，备注"
              v-model="addMaterialForm.remark"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="info" @click="addMaterialDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submitForm('addMaterialInfoForm')" :loading="submitLoading">提交</el-button>
        </div>
      </template>
    </el-dialog>
    <!--点击查看-->
    <el-dialog title="查看作品" v-model="previewVideoDialogVisible" destroy-on-close :width="'60%'">
      <template v-if="isVideo(filePathOrUrl)">
        <div style="width: 100%">
          <video width="100%" style="max-height: 600px;" controls>
            <source :src="filePathOrUrl" type="video/mp4">
            您的浏览器不支持视频播放。
          </video>
        </div>
      </template>
      <template v-else>
        <img :src="filePathOrUrl" alt="图片预览" style="width: 100%; max-height: 100%;">
      </template>
    </el-dialog>
    <!--修改素材标签-->
    <el-dialog v-model="editTagsDialogVisible"
               title="批量修改素材标签"
               width="40%"
               destroy-on-close
               :close-on-click-modal="false">
      <el-input v-model="editNewTags" placeholder="请输入新的素材标签，多个标签以;分割，例如：服装;电商"
                clearable></el-input>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="info" @click="editTagsDialogVisible = false">取消</el-button>
          <el-button type="primary" :loading="editTagLoading" @click="submitBatchEditTags">提交</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axiosInstance from '@/api/axiosInstance';
import {CirclePlusFilled, Search, Download, Position, Check, Edit} from '@element-plus/icons-vue';
import Pagination from "@/components/pagination/index.vue";
import axios from 'axios';

export default {
  name: "luma-account",
  components: {
    Edit,
    Check,
    Position,
    Pagination,
    CirclePlusFilled, Search, Download
  },
  computed: {
    isSuperAdmin() {
      return localStorage.getItem('isSuperAdmin') === 'true';
    },
    tenantId() {
      return localStorage.getItem('tenantId') || '';
    },
    username() {
      return localStorage.getItem('username') || ''
    },
    routePaths() {
      return localStorage.getItem('routePaths') || '';
    },
  },
  data() {
    return {
      selectedIds: [],
      queryParams: {
        tenantId: "",
        pageNum: 1,
        pageSize: 10,
        name: "",
        tag: "",
        remark: ""
      },
      tagQueryParams: {
        tenantId: "",
        pageNum: 1,
        pageSize: 40,
      },
      tenantInfoList: [], // 租户信息列表
      allTags: [],
      newTag: "",
      addTagLoading: false,
      materialInfoList: [],
      addMaterialDialogVisible: false,
      addMaterialForm: {
        name: '', // 素材名称
        levelType: 'end', // 素材阶段，原始 raw；预处理 pre；中间物 mid；成品 end
        type: 'video', // 素材类型，图片：image；视频：video；音频 audio；文案 text
        content: '', // 素材内容
        tags: [],
        description: '', // 素材描述
        ossUrl: '',
        remark: "",
        message: "",
      },
      addMaterialRules: {
        // name: [{required: true, message: '请输入素材名称', trigger: 'blur'}],
        // allTags: [{required: true, message: '请输入选择素材标签', trigger: 'blur'}],
        // levelType: [{required: true, message: '请选择素材阶段', trigger: 'blur'}],
      },
      accountInfoList: [],
      reportTableData: [],
      loading: false,
      total: 0,
      loginRecord: {},
      fileList: [],
      fileUploadOssInfoList: [],
      uploadVideoUrl: "",
      loginId: '',
      activeStep: 0,
      loginStatus: '',
      verifyCode: '',
      qrCodeRemainingTime: 0,
      autoWorkTimer: null,
      submitLoading: false,
      uploadFileLoading: false,
      rules: {
        platformUserId: [{required: true, message: '平台用户是必填项', trigger: 'blur'}],
        videoTitle: [{required: true, message: '视频标题是必填项', trigger: 'blur'}],
        videoDesc: [{required: true, message: '视频正文是必填项', trigger: 'blur'}],
        videoTags: [{required: true, message: '视频话题是必填项', trigger: 'blur'}],
        // file_url: [{required: true, message: '请上传一张图片', trigger: 'change'}]
      },
      previewVideoDialogVisible: false,
      dialogImageUrl: "",
      filePathOrUrl: '',
      recommendedImages: [],
      selectedImageUrl: "",
      hoveredImageUrl: "",
      editTagLoading: false,
      editTagsDialogVisible: false,
      editNewTags: "",
    };
  },
  created() {
    if (this.$route.query.platformUserId) {
      this.queryParams.platformUserId = this.$route.query.platformUserId
    }
    if (this.$route.query.publishVideo) {
      this.addMaterialDialogVisible = true;
      this.userInfoForm.platformUserId = this.$route.query.platformUserId;
    }
    this.fetchMaterialInfo();
  },
  mounted() {
    // 查询tag标签
    this.fetchTagInfos();
    // 查询素材
    this.fetchMaterialInfo();

    if (this.isSuperAdmin) {
      axiosInstance.get('/api/tenant')
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.tenantInfoList = response.data.data;
            } else {
              this.$message.error(response.data.msg);
            }
          }).catch(error => {
        console.error(error);
      });
    }
  },
  methods: {
    handleSelectionChange(rows) {
      console.log("handleSelectionChange", rows)
      // rows 对象数组 取id字段 转对应数组
      this.selectedIds = rows.map(item => item.id) || [];
    },
    // 新增素材的初始化
    resetAddMaterialForm() {
      console.log("resetAddMaterialForm")
      this.addMaterialForm = {
        name: '', // 素材名称
        levelType: 'end', // 素材阶段，原始 raw；预处理 pre；中间物 mid；成品 end
        type: 'video', // 素材类型，图片：image；视频：video；音频 audio；文案 text
        tags: [],
        description: '', // 素材描述
        ossUrl: '',
        remark: "",
        message: "",
      };
      this.fileList = [];
      this.fileUploadOssInfoList = [];
    },
    // 新增自定义素材
    addCustomTag() {
      if (this.newTag.trim().length === 0) {
        this.$message.error("请输入标签名称");
        return
      }
      this.newTag = this.newTag.trim();
      this.addTagLoading = true;
      axiosInstance.post('/api/tag/create', {
        "tenantId": this.tenantId,
        "name": this.newTag,
        "type": 'custom',
      }).then(response => {
        this.addTagLoading = false;
        if (response.data && response.data.code === 0) {
          this.fetchTagInfos();
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.addTagLoading = false;
        console.error(error)
      });
    },
    // 点击tag
    clickTag(tag) {
      tag.checked = !tag.checked
      console.log("clickTag current tag:", tag)
      let tagInArrayIndex = this.addMaterialForm.tags.indexOf(tag.name)
      // 字符串不存在于数组中，进行插入操作
      if (tag.checked && tagInArrayIndex === -1) {
        this.addMaterialForm.tags.push(tag.name)
      } else if (!tag.checked && tagInArrayIndex !== -1) {
        // 字符串已经存在于数组中，进行删除操作
        this.addMaterialForm.tags.splice(tagInArrayIndex, 1);
      }
      console.log("addMaterialForm.tags", this.addMaterialForm.tags)
    },
    // 查询tag
    fetchTagInfos() {
      if (!this.isSuperAdmin) {
        this.tagQueryParams.tenantId = this.tenantId;
      }
      axiosInstance.post('/api/tag/list', this.tagQueryParams)
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.allTags = response.data.data.results;
              this.allTags.forEach(tag => {
                tag.checked = false;
              })
              // console.log("this.allTags", this.allTags)
            } else {
              this.$message.error(response.data.msg);
            }
          }).catch(error => {
        console.error(error)
      });
    },
    // 查询素材信息
    fetchMaterialInfo() {
      this.loading = true
      if (!this.isSuperAdmin) {
        this.queryParams.tenantId = this.tenantId;
      }
      axiosInstance.post('/api/material/list', this.queryParams)
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.materialInfoList = response.data.data.results;
              this.total = response.data.data.total;
            } else {
              this.$message.error(response.data.msg);
            }
            this.loading = false
          }).catch(error => {
        this.loading = false
        console.error(error)
      });
    },
    // 删除素材
    clickDeleteMaterialInfo(row) {
      let that = this;
      this.$confirm('此操作将删除该记录, 是否继续?', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 用户点击了确定按钮，发送删除请求
        axiosInstance.get(`/api/material/delete/${row.id}`)
            .then(response => {
              that.$message({
                type: 'success',
                message: '删除成功!'
              });
              // 删除成功后，刷新列表
              that.fetchMaterialInfo();
            })
            .catch(error => {
              that.$message({
                type: 'error',
                message: '删除失败: ' + error.message
              });
            });
      }).catch(() => {
        // 用户点击了取消按钮
        that.$message({
          type: 'info',
          message: '已取消删除操作'
        });
      });
    },
    // 批量修改tag
    clickBatchEditTags() {
      this.editTagsDialogVisible = true;
    },
    async submitBatchEditTags() {
      if (this.editNewTags === "") {
        this.$message.error("请输入新的标签");
        return;
      }
      let edit_success = await this.editTags(this.selectedIds, this.editNewTags);
      if (edit_success) {
        this.editNewTags = "";
        this.editTagsDialogVisible = false; // 关闭对话框
        this.fetchMaterialInfo()
      }
    },
    clickEditTags(row) {
      this.editTags([row.id], row.tags)
    },
    // 编辑tags
    async editTags(ids, tags) {
      this.editTagLoading = true;
      const payload = {
        ids: ids || [],
        tags: tags || "",
      };
      try {
        const response = await axiosInstance.post('/api/material/edit_tags', payload)
        console.log("response", response)
        this.editTagLoading = false;
        if (response.data && response.data.code === 0) {
          this.$message.success('标签更新成功');
          return true;
        } else {
          this.$message.error(response.data.msg);
          return false;
        }
      } catch (error) {
        this.editTagLoading = false;
        this.$message.error('标签更新失败');
        return false;
      }
    },
    // 编辑备注
    editRemark(row) {
      const payload = {
        id: row.id,  // Assuming each row has a unique identifier
        remark: row.remark
      };
      axiosInstance.post('/api/material/edit_remark', payload)
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.$message.success('备注更新成功');
            } else {
              this.$message.error(response.data.msg);
            }
          })
          .catch(error => {
            console.error(error);
            this.$message.error('备注更新失败');
          });
    },
    // 编辑素材信息
    editMaterialContent(row) {
      const payload = {
        id: row.id,  // Assuming each row has a unique identifier
        content: row.content
      };
      axiosInstance.post('/api/material/edit_content', payload)
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.$message.success('内容更新成功');
            } else {
              this.$message.error(response.data.msg);
            }
          })
          .catch(error => {
            console.error(error);
            this.$message.error('内容更新失败');
          });
    },
    // 点击添加素材
    clickAddMaterialInfo() {
      this.addMaterialDialogVisible = true;
    },
    getTagType(status) {
      switch (status) {
        case 'publishing':
          return 'primary';
        case 'publish_success':
          return 'success';
        case 'publish_failed':
          return 'danger';
        case 'auditing':
          return 'info';
        case 'deleted':
          return 'danger';
        default:
          return '';
      }
    },
    getLevelTypeText(levelText) {
      // 素材类型，图片：image；视频：video；音频 audio；文案 text
      switch (levelText) {
        case 'raw':
          return '原始素材';
        case 'pre':
          return '标准素材';
        case 'mid':
          return '中间产物';
        case 'end':
          return '成品';
        default:
          return '未知类型';
      }
    },
    getMaterialTypeText(status) {
      // 素材类型，图片：image；视频：video；音频 audio；文案 text
      switch (status) {
        case 'image':
          return '图片';
        case 'video':
          return '视频';
        case 'audio':
          return '音频';
        case 'text':
          return '文案';
        default:
          return '未知类型';
      }
    },
    handleView(url) {
      this.filePathOrUrl = url;
      this.previewVideoDialogVisible = true;
    },
    isVideo(url) {
      try {
        let parsedUrl = new URL(url);
        const videoExtensions = ['.mp4', '.avi', '.mov', '.mkv', '.flv', '.wmv'];
        return videoExtensions.some(ext => parsedUrl.pathname.endsWith(ext) || parsedUrl.pathname.endsWith(ext.toUpperCase()));
      } catch (e) {
        console.error('Invalid URL:', url, e);
        return false;
      }
    },
    isImage(url) {
      try {
        const parsedUrl = new URL(url);
        const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp', '.svg'];
        return imageExtensions.some(ext => parsedUrl.pathname.endsWith(ext) || parsedUrl.pathname.endsWith(ext.toUpperCase()));
      } catch (e) {
        console.error('Invalid URL:', url, e);
        return false;
      }
    },

    async beforeUpload(file) {
      // console.log("beforeUpload: ", file)
      const isImage = file.type.startsWith('video/');
      if (!isImage) {
        this.$message.error('只能上传视频文件');
        return false
      }
      if (file.size / 1024 / 1024 > 50) {
        this.$message.error('只能上传视频文件');
        return false
      }
      let ossUploadUrl;
      try {
        const response = await axiosInstance.post(`/api/presigned_url_for_oss`, {
          filename: file.name,
          method: 'put',
          platformType: 'douyin',
        })
        console.log(response)
        if (response.data.code === 0) {
          ossUploadUrl = response.data.data.oss_url;
        } else {
          this.$message.error('【' + file.name + '】无法上传，获取上传URL失败');
          return false;
        }
      } catch (error) {
        console.error(error);
        this.$message.error('【' + file.name + '】无法上传，获取上传URL失败');
        return false;
      }
      let uploadInfo = {
        name: file.name,
        uid: file.uid,
        ossUrl: ossUploadUrl,
        uploadFinish: false,
      }
      // 遍历fileUploadOssInfoList
      for (let i = 0; i < this.fileUploadOssInfoList.length; i++) {
        if (this.fileUploadOssInfoList[i].uid === file.uid) {
          this.fileUploadOssInfoList[i] = uploadInfo
          return true
        }
      }
      // 新增
      this.fileUploadOssInfoList.push(uploadInfo)
      return true
    },

    uploadToOss(entity) {
      // console.log("uploadToOss entity", entity)
      // console.log("uploadToOss this.fileList", this.fileList)
      let file = entity.file
      let selectIndex = -1;
      for (let i = 0; i < this.fileUploadOssInfoList.length; i++) {
        if (this.fileUploadOssInfoList[i].uid === file.uid) {
          selectIndex = i;
        }
      }
      if (selectIndex === -1) {
        this.$message.error('【' + file.name + '】无法上传，获取上传URL失败');
        return
      }
      let ossUrl = this.fileUploadOssInfoList[selectIndex].ossUrl
      this.$message.info("【" + file.name + "】" + "正在上传，请稍后");
      axios.put(ossUrl, file, {
        headers: {
          'Content-Type': 'video/*',
        }
      }).then(response => {
        console.info("【" + file.name + "】" + "上传成功", response)
        this.$message.success("【" + file.name + "】" + "上传成功");
        this.fileUploadOssInfoList[selectIndex].uploadFinish = true
      }).catch(error => {
        // 移除
        this.fileUploadOssInfoList.splice(selectIndex, 1)
        this.$message.error("【" + file.name + "】" + "上传失败");
        console.error(error);
      });
    },
    handleRemoveFile(file, fileList) {
      console.log("handleRemoveFile", file, fileList)
      let selectIndex = -1;
      for (let i = 0; i < this.fileUploadOssInfoList.length; i++) {
        if (this.fileUploadOssInfoList[i].uid === file.uid) {
          selectIndex = i;
        }
      }
      if (selectIndex !== -1) {
        this.fileUploadOssInfoList.splice(selectIndex, 1)
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning('最多支持同时上传50个');
    },
    handlePictureCardPreview(file) {
      console.log("handlePictureCardPreview file", file)
      this.filePathOrUrl = file.url;
      this.previewVideoDialogVisible = true;
    },
    // 提交素材上传
    submitForm(formName) {
      let that = this;
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          console.log("表单验证未通过");
          return false;
        }
        if (this.addMaterialForm.type === 'video' && this.fileUploadOssInfoList.length === 0) {
          this.$message.warning('请上传素材');
          return false;
        }
        if (this.addMaterialForm.type === 'video' && this.fileUploadOssInfoList.length > 0) {
          for (let i = 0; i < this.fileUploadOssInfoList.length; i++) {
            if (!this.fileUploadOssInfoList[i].uploadFinish) {
              this.$message.warning("【" + this.fileUploadOssInfoList[i].name + "】素材上传未完成");
              return false;
            }
          }
        }
        if (this.addMaterialForm.type === 'text' && this.addMaterialForm.content === '') {
          this.$message.warning('请输入素材内容');
          return false;
        }
        // 提交表单逻辑
        console.log('表单验证通过，可以提交数据', this.addMaterialForm);
        that.submitLoading = true;
        // 用户点击了确定按钮
        let addMaterialFormCopy = {...this.addMaterialForm};
        addMaterialFormCopy.fileList = this.fileUploadOssInfoList;
        addMaterialFormCopy.tenantId = this.tenantId;
        axiosInstance.post(`/api/material/upload`, addMaterialFormCopy).then(response => {
          that.submitLoading = false;
          if (response.data && response.data.code === 0) {
            that.$message({
              type: 'success',
              message: '素材上传成功!'
            });
            that.addMaterialDialogVisible = false;
            // 刷新素材信息列表
            that.fetchMaterialInfo();
          } else {
            that.$message({
              type: 'error',
              message: '素材上传失败: ' + response.data.msg
            });
          }
        }).catch(error => {
          that.submitLoading = false;
          that.$message({
            type: 'error',
            message: '素材上传失败: ' + error.message
          });
        });
      });
    },
    // 复制到粘贴板
    async handleCopy(text) {
      await navigator.clipboard.writeText(text);
      this.$message.success('复制成功');
    },
  },
  beforeUnmount() {
  }
};
</script>

<style scoped>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 20px;
}

.main-header-h1 {
  text-align: center;
  color: #333;
}

#button-container {
  text-align: center;
  margin-bottom: 20px;
}

.add-user-button {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-user-button:hover {
  background-color: #0056b3;
}

#cookie-info-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.cookie-info {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.cookie-info h2 {
  margin: 0 0 10px;
  font-size: 1.5em;
  font-weight: 600;
  color: #333;
}

.cookie-info span {
  margin-bottom: 5px;
  font-size: 1em;
  color: #555;
}

.cookie-status-valid {
  color: green;
}

.cookie-status-invalid {
  color: red;
}

.step-content {
  text-align: center;
  margin-top: 20px;
}

.highlight {
  color: red;
  font-weight: bold;
}

.photo-wall {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-height: 200px;
  overflow: auto;
}

.photo-item {
  width: calc(33.33% - 10px);
  cursor: pointer;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.photo-item img {
  width: 100%;
  height: auto;
}

.photo-item.selected {
  border: 2px solid #409eff;
}

.actions {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background: rgba(0, 0, 0, 0.5);
  color: #d9ecff;
  padding: 5px 0;
}

.actions span {
  cursor: pointer;
}

.tag-container {
  display: flex;
  flex-direction: column;
}

.tag-flex {
  display: flex;
  flex-wrap: wrap;
}

.tag-gap-2 {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.custom-tag-input {
  margin-top: 1rem; /* 增加顶部边距，以确保与上方标签列表之间有间距 */
}
</style>