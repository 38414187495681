<!-- src/App.vue -->
<template>
  <router-view></router-view>
</template>

<script>
import Home from './views/Home.vue';

export default {
  name: 'App',
  components: {Home}
};
</script>

<style>
body, html, #app {
  height: 100%;
  margin: 0;
}
</style>